import React from 'react'
import { Trademark } from '../../layout/components/dom';
import constructImg from './construct.png';

function UnderDevelopment() {



  return (
    <div>
        <header style={{display: 'flex', alignItems: 'center', padding: '10px 0px'}}>

          <div style={{marginRight: 25, marginLeft: 25, fontWeight: 600, fontSize: '1.4em', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Trademark text='Creative Monkey' size={0.5}/><i style={{marginLeft: 5, color: '#F0732C'}}>Softworks</i></div>
        
        </header>


        <div style={{
          backgroundImage: `url(${constructImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}></div>

        <div style={{display: 'flex', justifyContent: 'center', color: '#F0732C'}}>
            <i style={{margin: 20, fontSize: '2em', fontWeight: 300, display: 'flex', alignItems: 'center'}}>Under Construction<span className='material-symbols-rounded' style={{fontSize: '1.2em', marginLeft: 15}}>construction</span></i>
        </div>



    </div>
  )
}

export default UnderDevelopment